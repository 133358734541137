@use '../../../../styles/colors' as *;
@use '../../../../styles/typography' as *;

.ready-container {
    position: relative;
    height: 100vh;
    z-index: 3;
}

.ready-bird {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 0;
    will-change: transform;
}

.ready-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    height: 100vh;
    @media (max-width: 1000px) and (max-height: 450px) {
        gap: 10px;
    }
}

.ready-title {
    font-size: 2.9rem;
    text-align: center;
    z-index: 1;
    display: flex;
    @media (max-width: 768px) {
        font-size: $font-size-h2;
        width: 90%;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.ready-span {
    white-space: nowrap;
    padding: 0 5px;
    margin-left: -5px;
    background-size: 200%;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}

.ready-desc {
    font-size: 20px;
    max-width: 690px;
    width: 100%;
    text-align: center;
    z-index: 1;
    @media (max-width: 450px) {
        font-size: 16px;
        width: 90%;
    }
}

.ready-input {
    max-width: 744px;
    width: 100%;
    @media (max-width: 450px) {
        width: 95%;
        margin: 0 auto;
    }
}

.ready-cta {
    overflow: hidden;
    position: relative;
    max-width: 242px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 0rem 1.8rem;
    background: hsla(0, 0%, 13%, 1);
    color: $eth-white;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    height: 48px;
    font-family: 'DIN Next W1G';
    cursor: pointer;
    z-index: 1;
}

.ready-cta:hover {
    background-color: #000000;
}

.ready-cta:focus {
    outline: none;
}

.ready-cta .ripple {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2); /* Ripple color */
    transform: scale(0);
    animation: ripple-animation 0.6s linear;
}

@keyframes ripple-animation {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

.ready-1 {
    padding: 0 3px;
}
.ready-2 {
    padding: 0 3px;
}
.ready-3 {
    padding: 0 3px;
}
.ready-4 {
    padding: 0 3px;
}
.ready-5 {
    padding: 0 3px;
}
