@use '../../../../styles/colors.scss' as *;

.expect-container {
    height: 100vh;
    width: 100%;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    gap: 350px;
    will-change: transform;

    @media (max-width: 1100px) {
        gap: 200px;
    }

    @media (max-width: 1000px) and (max-height: 450px) {
        gap: 100px;
    }
    @media (max-width: 450px) {
        transform: translate3d(-50%, -55%, 0);
        height: 100vh !important;
            @media (min-height: 780px) {
            transform: translate3d(-50%, -58%, 0);
        }
    }
}

.expect-image {
    top: 0;
    right: 0;
    height: fit-content;
    position: absolute;
}

.expect-bird {
    position: absolute;
    scale: (3);
    left: 32%;
    width: 100%;
    top: 6%;
    z-index: 1;
}

.expect-left-container {
    display: none;
    position: relative;
    height: 100%;
    width: 50%;
    justify-content: end;
    align-items: start;
}

.expect-noise {
    position: absolute;
    top: 29%;
    left: 78%;
    transform: translate3d(-50%, -50%, 0);
    z-index: -1;
    will-change: transform;
}

.expect-right-container {
    display: none;
    position: relative;
    width: 60%;
    height: 100%;
    z-index: 10;
}

.expect-text-box {
    position: absolute;
    left: 0;
    top: 20%;
    width: 100%;
    max-width: 400px;
    padding: 1.2rem 0;
    background-color: #222222;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
    border-radius: 6px;
    font-weight: 400;
    color: white;
    border-left: 1px solid rgba(0, 0, 0, 0.3);
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    @media (max-width: 450px) {
        top: 2%;
        position: static;
        height: 200px;
    }
}

.expect-text-title {
    padding: 0 2rem;
    font-size: 24px;
    @media (max-height: 800px) {
        font-size: 20px;
    }
}

.expect-text-desc {
    padding: 0 2rem;
    font-size: 20px;
    @media (max-height: 800px) {
        font-size: 16px;
    }
    @media (max-width: 450px) {
        height: 90px;
    }
}

.expect-heading {
    position: absolute;
    top: 9%;
    font-size: 40px;
    @media (max-width: 1100px) {
        font-size: 32px;
    }
}

.expect-highlight {
    padding: 0 5px;
    white-space: nowrap;
    background-size: 200%;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}
.leadership-br {
    @media (max-width: 500px) {
        display: none;
    }
}
.expect-seperator-black {
    background-color: rgba(0, 0, 0, 0.4);
    height: 1px;
    width: 100%;
}
.expect-seperator {
    background-color: rgba(255, 255, 255, 0.1);
    height: 1px;
    width: 100%;
}

.seperator-container {
    width: 99%;
    margin: auto;
}

.expect-container-mob {
    display: none;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (max-width: 450px) {
        width: 90%;
    }
}

.expect-bird-mob {
    margin-right: -70px;
}

.expect-left-container-mob {
    display: none;
    position: relative;
    width: 90%;
    justify-content: center;
    align-items: start;
}

.expect-noise-mob {
    width: 170%;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: -1;
    will-change: transform;
}

.expect-right-container-mob {
    display: none;
    position: relative;
    width: 100%;
    height: 500px;
    z-index: 10;
}

.expect-text-box-mob {
    position: absolute;
    left: 0;
    top: 20%;
    width: 100%;
    max-width: 400px;
    padding: 1.2rem 0;
    background-color: #222222;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
    border-radius: 6px;
    font-weight: 400;
    color: white;
    border: 1px solid rgb(197, 197, 197, 0.3);
}

@media (min-width: 400px) {
    .expect-text-box-mob {
        box-shadow: 0px 2px 4px 0px #00000059;
        box-shadow: 0px 2px 1px 0px #ffffff59 inset;
    }
    .expect-text-box{
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3490196078), 0px 2px 2px 0px rgba(255, 255, 255, 0.3490196078) inset;
    }
}

.expect-text-title-mob {
    padding: 0 2rem;
}

.expect-heading-mob {
    font-size: 32px;
    margin-top: 68px;
    text-align: center;
}

.expect-highlight-mob {
    padding: 0 5px;
    margin-left: -5px;
    white-space: nowrap;
    background-size: 200%;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}

.leadership-br {
    @media (max-width: 500px) {
        display: none;
    }
}

.expect-seperator-black-mob {
    background-color: rgba(0, 0, 0, 0.4);
    height: 1px;
    width: 100%;
}

.expect-seperator-mob {
    background-color: rgba(255, 255, 255, 0.1);
    height: 1px;
    width: 100%;
}

.seperator-container-mob {
    width: 99%;
    margin: auto;
}

.expect-point {
    opacity: 1;
    display: none;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 100px;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    will-change: transform;
    @media (max-width: 450px) {
        display: block;
    }
}
.expect-cards-mob {
    display: flex;
    justify-content: center;
    align-items: start;
    margin-bottom: 20px;
    margin-top: 40px;
}
