@use '../../../../styles/colors.scss' as *;

.seconds-container {
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.afternoon-color {
    display: none;
    background: url('../../../../assets/backgrounds/afternoon-color.png');
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
}

.seconds-background {
    display: none;
}
.card-container {
    width: 25%;
}

.seconds-background {
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
}
.seconds-line {
    display: none;
    background: url('../../../../assets/img/seconds-line.svg');
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: -1;
    will-change: transform;
}

.seconds-title {
    position: absolute;
    width: 90%;
    margin: auto;
    top: 20%;
    left: 5%;
    font-size: 40px;
}

.seconds-span {
    white-space: nowrap;
    padding: 0 5px;
    margin-left: -5px;
    background-size: 200%;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}

.cards-container {
    width: 90%;
    min-height: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 30px;
    @media (max-width: 1000px) and (max-height: 450px) {
        margin-top: 20px !important;
    }
}

.seconds-card-screen {
    max-width: 385px;
    width: 100%;
    height: auto;
    border-radius: 12px;
    background-color: #e7e7e4;
}

.seconds-card-title {
    font-size: 28px;
    width: 100%;
    @media (max-width: 1100px) {
        font-size: 24px;
    }
    @media (max-width: 1100px) {
        font-size: 18px;
    }
}

.seconds-card-details {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 11px;
    margin-top: 24px;
}

.seconds-card-desc {
    font-size: 20px;
    line-height: 24px;
    max-width: 250px;
    width: 100%;
    @media (max-width: 1400px) {
        font-size: 16px;
    }
    @media (max-width: 1100px) {
        font-size: 14px;
    }
}

.ready-container {
    display: none;
}

// Mobile version
.afternoon-color-mob {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    background: url('../../../../assets/backgrounds/afternoon-color-mob.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}

.seconds-content-mob {
    display: none;
    height: 100vh;
    padding: 0 10px;
    margin-top: 160px;
}

.seconds-background-mob {
    background: url('../../../../assets/backgrounds/seconds-mobile-bg.png');
    background-color: #eeedea;
    width: 100%;
    height: 100%;
    background-size: cover;
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
}

.seconds-line-mob {
    display: none;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: -1;
    will-change: transform;
}

.seconds-title-mob {
    font-size: 40px;
    margin-bottom: 120px;
    @media (max-width: 450px) {
        margin-bottom: 34px;
    }
    @media (max-width: 400px) {
        font-size: 30px;
    }
}

.seconds-span-mob {
    white-space: nowrap;
    padding: 0 5px;
    margin-left: -5px;
    background-size: 200%;
    background-image: linear-gradient(to right, transparent 50%, $eth-highlight-orange 50%);
}

.cards-container-mob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.seconds-card-screen-mob {
    max-width: 312px;
    width: 100%;
    height: 211px;
    border-radius: 12px;
    background-color: transparent;
    background-size: 125% 102%;
    background-position: center;
    border: 1px solid #b8b8b0;
}

.seconds-card-bg-1 {
    background-image: url('../../../../assets/img/mob-step-1.png');
}
.seconds-card-bg-2 {
    background-image: url('../../../../assets/img/mob-step-2.png');
}
.seconds-card-bg-3 {
    background-image: url('../../../../assets/img/mob-step-3.png');
}
.seconds-card-bg-4 {
    background-image: url('../../../../assets/img/mob-step-4.png');
}

.seconds-card-title-mob {
    font-size: 24px;
}

.seconds-card-details-mob {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    gap: 11px;
    margin-top: 24px;
}

.seconds-card-desc-mob {
    font-size: 16px;
    line-height: 24px;
    max-width: 250px;
}

.ready-container-mob {
    display: none;
}
