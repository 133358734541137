@use '../../../../styles/colors' as *;
@use '../../../../styles/typography' as *;

.welcome {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;

    .background-shadow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../../../../assets/backgrounds/landing.webp');
        background-size: cover;
        background-position: center;
    }

    .background-lines {
        position: absolute;
        top: 36px;
        left: -24px;
        scale: 1.2;
        width: 100%;
        height: 100%;
        @media (min-width: 450px) {
            filter: brightness(0.8);
        }
        @media (max-width: 1800px) {
            top: 50px;
            left: 0px;
            scale: 1.2;
        }
        @media (max-width: 1700px) {
            top: 55px;
            left: 50px;
            scale: 1.2;
        }
        @media (max-width: 1600px) {
            top: 50px;
            left: 80px;
            scale: 1.2;
        }
        @media (max-width: 1500px) {
            top: 50px;
            left: 90px;
            scale: 1.2;
        }
        @media (max-width: 1350px) {
            top: 42px;
            left: 182px;
            scale: 2;
        }
    }

    .linecontainer {
        position: absolute;
        top: 0;
        left: 50%;
        overflow: hidden;
        opacity: 0;
        height: 100vh;
        width: 100%;
        min-width: 1980px;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
    }

    .mobilelinecontainer {
        position: absolute;
        top: 50%;
        left: 50%;
        overflow: hidden;
        opacity: 0;
        width: 100%;
        min-width: 1980px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lineleft {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .linebackground {
        position: absolute;
        top: 0;
        left: 45%;
        width: auto;
        transform: translate3d(-50%, 0, 0);
        height: 100vh;
        will-change: transform;
    }

    .handcontainer {
        position: absolute;
        display: flex;
        justify-items: center;
        align-items: flex-end;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .hand {
        width: 100%;
        height: 50vh;
        opacity: 0;
    }

    .handleft {
        transform: translate3d(10%, 10%, 0);
        will-change: transform;
    }

    .handpoints {
        opacity: 0;
        width: 100%;
        height: 45vh;
        scale: 1.1;
        rotate: -2deg;
    }

    .birdcontainer {
        position: absolute;
        display: flex;
        justify-items: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        @media (max-width: 500px) {
            padding: 0 2rem;
        }
    }

    .bird {
        width: 100%;
        opacity: 0;
        transform: translate3d(7%, 90%, 0);
        height: 30vh;
        will-change: transform;
    }

    .birdpoints {
        width: 100%;
        transform: translate3d(8%, -15%, 0);
        height: 30vh;
        opacity: 0;
        will-change: transform;
    }

    .birdpointswhite {
        width: 100%;
    }

    .content {
        position: relative;
        z-index: 1;
        transform: translate3d(0, 0, 0);
        width: 75%;
        margin: 0 auto;
        padding: 1.5rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: 'DIN Next W1G Light';
        will-change: transform;

        @media (max-width: 1000px) and (max-height: 450px) {
            width: 95%;
        }
    }

    .heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }

    .headingcontainer {
        position: absolute;
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .title-subheading {
        color: $eth-white-background-light;
        font-size: $font-size-c;
        user-select: none;
    }

    .title-logo {
        width: 190px;
        user-select: none;
        opacity: 0;
    }

    .title {
        font-size: 80px;
        font-weight: 400;
        color: $eth-white;
        max-width: 600px;
        line-height: 0.87;
        margin-bottom: 1.5rem;
        user-select: none;
        font-family: 'DIN Next W1G';
        span {
            display: block;
        }
        @media (max-width: 1550px) {
            font-size: 70px;
        }
        @media (max-width: 1450px) {
            font-size: 60px;
        }
    }

    .title-subtitle {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
        max-width: 400px;
        margin-bottom: 2rem;
        user-select: none;
        font-family: 'DIN Next W1G';

        @media (max-width: 1550px) {
            font-size: 1.04rem;
        }
        @media (max-width: 1450px) {
            font-size: 1rem;
        }
    }

    .title-cta {
        max-width: 242px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 0rem 1.8rem;
        background: $eth-black-light;
        color: $eth-white;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        user-select: none;
        opacity: 0;
        height: 48px;
        cursor: pointer;
        font-family: 'DIN Next W1G';
        position: relative;
        overflow: hidden;
        @media (max-width: 1550px) {
            gap: 8px;
        }
        @media (max-width: 1450px) {
            gap: 6px;
        }
    }
    .title-cta:hover {
        background-color: #000000;
    }
    .title-bold {
        font-weight: 700;
    }

    .title-cta:focus {
        outline: none;
    }

    .title-cta .ripple {
        position: absolute;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.2); /* Ripple color */
        transform: scale(0);
        animation: ripple-animation 0.6s linear;
    }

    @keyframes ripple-animation {
        to {
            transform: scale(4);
            opacity: 0;
        }
    }
}
