@use '../../styles/colors' as *;
@use '../../styles/components' as *;
@use '../../styles/spacing' as *;
@use '../../styles/typography' as *;

.custom-highlight {
    width: 0px !important;
    height: 0px !important;
}

.introjs-helperLayer {
    box-shadow: rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
    padding: 10px;
    transform: translate(-10px, -10px);
}

.custom-tooltip {
    position: relative;
    margin: 24px;
    width: 424px !important;
    max-width: none !important;
    padding: $space-curve-hecto !important;
    gap: 20px;
    border-radius: $border-radius-12 !important;
    background: $eth-black-light !important;
    border: 1px solid #00000080;
    opacity: 0px;

    &.first-step {
        top: calc(50% - 114px) !important;
        left: calc(50% - 212px) !important;
        transform: translate(-48px, -24px); 

        & .introjs-arrow {
            display: none !important;
        }

        .introjs-prevbutton {
            display: none !important;
        }
    }

    .introjs-tooltip-header {
        position: absolute;
        left: 24px;
        bottom: 33px;

        & .introjs-skipbutton {
            font-size: $font-size-c !important;
            color: $eth-white !important;
            font-weight: $font-weight-semi-bold !important;

            &:hover {
                color: $eth-white-with-opacity !important;
            }
        }
    }
}

.introjs-tooltiptext {
    padding: 0 !important;
    color: $eth-white !important;
    font-size: $font-size-l3;
    margin-bottom: $space-curve-centi !important;

    .step-title {
        font-weight: $font-weight-bold;
        margin-bottom: $space-curve-nano;
    }

    .step-text {
        opacity: 0.8;
    }
}

.introjs-arrow {
    border: 15px solid transparent;

    &.bottom {
        bottom: -30px;
        left: 30px;
        border-top-color: $eth-black-light !important;
    }

    &.top {
        top: -30px;
        left: 30px;
        border-bottom-color: $eth-black-light !important;
    }
    &.bottom-middle {
        bottom: -30px;
        left: 50%;
        margin-left: -5px;
        border-top-color: $eth-black-light !important;
    }
    &.top-middle {
        top: -30px;
        left: 50%;
        margin-left: -5px;
        border-bottom-color: $eth-black-light !important;
    }
    &.left {
        left: -30px;
        top: 30px;
        border-right-color: $eth-black-light !important;
    }
    &.left-bottom {
        left: -30px;
        bottom: 30px;
        border-right-color: $eth-black-light !important;
    }
}

.introjs-tooltipbuttons {
    width: 100% !important;
    display: flex !important;
    justify-content: right !important;
    gap: $space-curve-micro !important;
    padding: 0 !important;

    border-top: none !important;

    &::after {
        display: none !important;
    }

    .introjs-button {
        border-radius: $border-radius-default;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        padding: 8px 16px 8px 36px !important;  
        transition: background-color 0.3s ease;
        border: 1px solid;
    
        align-self: stretch;
        font-size: $font-size-c !important;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-shadow: none !important;
    
        &.button-only-icon {
            padding: 14px;
    
            .button-icon {
                line-height: 0;
            }
        }
    
        &:hover {
            background-color: $eth-black !important;
            border-color: $eth-black-light !important;
        }
    
        &:disabled {
            background-color: $eth-grey;
            border-color: $eth-grey;
            color: $eth-black-light-31;
        }
    
        &.inverted {
            border-color: $eth-white;
            background-color: $eth-white;
            color: $eth-black-light;
    
            span svg {
                fill: $eth-black-light;
            }
    
            &:hover {
                background-color: $eth-white-with-opacity;
                border-color: $eth-white;
                color: $eth-black;
            }
    
            &:disabled {
                background-color: $eth-white;
                opacity: 0.4;
                border-color: $eth-white;
                color: $eth-black-light-60;
            }
        }
    }
    
    .introjs-nextbutton {
        border-color: $eth-white !important;
        background-color: $eth-white !important;
        color: $eth-black-light !important;

        &::before {
            content: '';
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            background-image: url('../../assets/icons/arrow-forward-small.svg');
            background-size: cover;
            background-repeat: no-repeat;
        }

        span svg {
            fill: $eth-black-light;
        }

        &:hover {
            background-color: $eth-white-with-opacity !important;
            border-color: $eth-white !important;
            color: $eth-black !important;
        }

        &.introjs-disabled {
            background-color: $eth-white !important;
            opacity: 0.4 !important;
            border-color: $eth-white !important;
            color: $eth-black-light-60 !important;
        }
    }

    .introjs-prevbutton {
        border-color: $eth-white !important;
        background-color: $eth-black-light !important;
        color: $eth-white !important;
        box-shadow: none !important;

        &::before {
            content: '';
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            background-image: url('../../assets/icons/arrow-back-white.svg');
            background-size: cover;
            background-repeat: no-repeat;
        }
        
        span svg {
            fill: $eth-white;
        }

        &:hover {
            background-color: $eth-white-with-opacity !important;
            border-color: $eth-white-with-opacity !important;
            color: $eth-black-light !important;

            span svg {
                fill: $eth-black-light;
            }

            &::before {
                background-image: url('../../assets/icons/arrow-back.svg');
            }
        }

        &.introjs-disabled {
            background-color: $eth-black-light !important;
            opacity: 0.4 !important;
            border-color: $eth-white !important;
            color: $eth-white !important;
        }
    }

    // .introjs-donebutton {
    //     padding: 8px 16px 8px 16px !important;

    //     &::before {
    //         display: none !important;
    //     }
    // }
}
